/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Lottie from "lottie-react";
import { Footer, Navbar } from "../../Components";
import { FaArrowRight } from "react-icons/fa";
import { Typewriter } from "react-simple-typewriter";
import ArrowsDownAnimationLottie from '../../assets/lottie/arrows-down-lottie.json';


export const HomeScreen = () => {

    return(
        <>
            <div className="font-inter antialiased bg-dark tracking-tight bg-black">
                <div className="flex flex-col min-h-screen overflow-hidden">
                    <Navbar />
                    
                    {/* Hero */}
                    <section className="relative w-full h-screen flex items-center justify-center text-center text-white">
                        {/* Background image */}
                        <div className="absolute inset-0 h-full w-full">
                            <img 
                                src={require('../../assets/images/hero-background.png')} 
                                alt="Hero background" 
                                className="w-full h-full object-cover"
                            />
                            <div className="absolute inset-0 bg-black opacity-60"></div>
                        </div>

                        {/* Hero content */}
                        <div className="relative z-10 max-w-4xl px-2">
                            {/* Lottie Animation */}
                            <h1 
                                className="text-5xl md:text-7xl font-extrabold tracking-tight leading-tight mb-4"
                                data-aos="fade-down"
                                data-aos-delay="300"
                            >
                                Welcome to Tokyo <span className="font-thin">Digital</span>
                            </h1>
                            <p 
                                className="text-lg md:text-2xl text-gray-300 mb-12" 
                                data-aos="fade-up"
                                data-aos-delay="500"
                            >
                                Transforming digital experiences through 
                                <span className="font-bold ml-1">
                                    <Typewriter
                                        words={['bespoke mobile apps', 'sleek, modern websites', 'engaging social media strategies']}
                                        typeSpeed={90}
                                        cursor
                                        cursorStyle='|'
                                        loop={false}
                                    />
                                </span>
                            </p>

                            <a 
                                href="mailto:hello@tokyodigital.com.au" 
                                className="bg-white text-white px-12 py-4 rounded-full shadow-lg hover:shadow-xl hover:bg-gray-100 transition transform duration-300 ease-in-out font-bold text-lg"
                                data-aos="zoom-in"
                                data-aos-delay="700"
                            >
                                <span className="text-black">Get in Touch</span>
                            </a>
                            <div className="mt-5 flex justify-center" data-aos="fade-up" data-aos-delay="600">
                                <Lottie 
                                    className="w-[100px] h-[100px] md:w-[100px] md:h-[100px]"
                                    animationData={ArrowsDownAnimationLottie}
                                />
                            </div>
                        </div>
                    </section>



                    {/* work */}
                    <section id="work" className="py-0">
                        <div className="max-w-full mx-auto flex flex-col md:flex-row mt-0">
                            {/* Image Section with Background Image */}
                            <div className="md:h-screen md:w-1/2 relative"> 
                                <div className="absolute inset-0 h-full w-full">
                                    <img 
                                        src={require('../../assets/images/work-image.png')} 
                                        alt="Hero background" 
                                        className="w-full h-full object-cover"
                                    />
                                    <div className="absolute inset-0 bg-black opacity-60"></div>
                                </div>

                                {/* Hero content */}
                                <div className="relative z-10 flex justify-center items-center h-full">
                                    <img 
                                        src={require('../../assets/images/youcrew.png')} 
                                        alt="YouCrew Logo" 
                                        className="w-3/4 md:w-2/3 lg:w-full h-auto" // Adjust the size for responsiveness
                                    />
                                </div>
                            </div>

                            {/* Text Section */}
                            <div className="bg-black text-white flex flex-col justify-center h-screen md:w-1/2 p-8 relative z-10 mb-5"> 
                                <h2 className="text-4xl font-bold mb-4">Work We've Done</h2>
                                <div className="flex flex-row items-center justify-center my-5">
                                    {/* Text Section */}
                                    <div className="w-3/1 p-1"> {/* Adjust width if necessary */}
                                        <p className="font-bold text-xl">Dig Assist</p>
                                        <p className="font-extralight">
                                            Dig Assist provides top-notch labor and equipment solutions, helping you get the job done efficiently. Whether you're looking for skilled professionals or reliable machinery, we’ve got you covered. With unmatched service and expertise, we make sure your project runs smoothly from start to finish.
                                        </p>
                                    </div>

                                    {/* Button Section */}
                                    <div className="flex items-center justify-center w-1/2 ">
                                        <a 
                                            target="_blank"
                                            href="https://dig-assist.netlify.app/" 
                                            className="flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-lg hover:bg-gray-200 transition duration-200" // Circle button styles
                                        >
                                            <FaArrowRight className="text-black"/>
                                        </a>
                                    </div>
                                </div>

                                <div className="flex flex-row items-center justify-center my-5">
                                    {/* Text Section */}
                                    <div className="w-3/1 p-1"> 
                                        <p className="font-bold text-xl">YouCrew</p>
                                        <p className="font-extralight">
                                            YouCrew takes the hassle out of job searches and hiring. Our platform speeds up the recruitment process with efficient job matching and comprehensive profiles, ensuring you find and fill positions quickly. Simplifying employment for both job seekers and businesses, YouCrew makes hiring faster and easier.
                                        </p>
                                    </div>

                                    {/* Button Section */}
                                    <div className="flex items-center justify-center w-1/2 "> 
                                        <a 
                                            target="_blank"
                                            href="https://www.youcrew.com.au" 
                                            className="flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-lg hover:bg-gray-200 transition duration-200" // Circle button styles
                                        >
                                            <FaArrowRight className="text-black"/>
                                        </a>
                                    </div>
                                </div>

                                <div className="flex flex-row items-center justify-center my-5">
                                    {/* Text Section */}
                                    <div className="w-3/1 p-1"> 
                                        <p className="font-bold text-xl">FIFO Clock</p>
                                        <p className="font-extralight">
                                            FIFO Clock is your go-to tool for managing rosters, syncing with friends, and tracking your time off. Effortlessly organize your schedule and make every moment count, whether you’re planning for work or well-deserved rest.
                                        </p>
                                    </div>

                                    {/* Button Section */}
                                    <div className="flex items-center justify-center w-1/2 "> 
                                        <a 
                                            target="_blank"
                                            href="https://www.fifoclock.com" 
                                            className="flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-lg hover:bg-gray-200 transition duration-200" rel="noreferrer" // Circle button styles
                                        >
                                            <FaArrowRight className="text-black"/>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>


                    <section id="services" className="py-0">
                        <div className="max-w-full mx-auto flex flex-col md:flex-row mt-0">
                            {/* Image Section */}
                            <div className="relative h-64 md:h-screen w-full md:w-1/2 block md:hidden"> 
                                <div className="absolute inset-0 h-full w-full">
                                    <img 
                                        src={require('../../assets/images/services-image.png')} 
                                        alt="services background" 
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                            </div>

                            {/* Text Section */}
                            <div className="bg-black text-white flex flex-col justify-center h-auto md:h-screen w-full md:w-1/2 p-6 md:p-8 relative z-10"> 
                                <h2 className="text-3xl md:text-4xl font-bold mb-4">Services</h2>
                                <div className="my-5">
                                    <p className=" text-lg font-bold">Mobile App Development</p>
                                    <p className="text-lg font-light">
                                        Our mobile app development transforms your vision into a seamless, interactive experience. We take inspiration directly from you, ensuring the app reflects your personality and goals. Your imagination drives our design process, and we bring it to life with an app that looks stunning and flows effortlessly.
                                    </p>
                                </div>
                                <div className="my-5">
                                    <p className=" text-lg font-bold">Web Design</p>
                                    <p className="text-lg font-light">
                                        A great website goes beyond aesthetics—it's about creating a seamless, engaging experience. With clean, responsive layouts and intuitive navigation, we ensure every detail keeps visitors engaged. Our designs align with your goals while prioritizing user experience
                                    </p>
                                </div>

                                <div className="my-5">
                                    <p className=" text-lg font-bold">Social Media Management</p>
                                    <p className="text-lg font-light">
                                        Our social media management elevates your brand's online presence by turning your voice, values, and goals into engaging content. Whether building awareness, driving engagement, or boosting conversions, we tailor our approach to meet your unique objectives across all platforms.
                                    </p>
                                </div>
                            </div>
                            {/* Image Section */}
                            <div className="relative h-64 md:h-screen w-full md:w-1/2 hidden md:block"> 
                                <div className="absolute inset-0 h-full w-full">
                                    <img 
                                        src={require('../../assets/images/services-image.png')} 
                                        alt="services background" 
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="about" className="py-0">
                        <div className="max-w-full mx-auto flex flex-col md:flex-row mt-0">
                            {/* Image Section */}
                            <div className="relative h-64 md:h-screen w-full md:w-1/2"> 
                                <div className="absolute inset-0 h-full w-full">
                                    <img 
                                        src={require('../../assets/images/about-image.png')} 
                                        alt="Hero background" 
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                            </div>

                            {/* Text Section */}
                            <div className="bg-black text-white flex flex-col justify-center h-auto md:h-screen w-full md:w-1/2 p-6 md:p-8 relative z-10"> 
                                <h2 className="text-3xl md:text-4xl font-bold mb-4">About Us</h2>
                                <div className="mt-5">
                                    <p className="text-base md:text-lg font-light my-4 md:my-5">
                                        At Tokyo Digital, we’re not just your typical web and mobile design agency – we’re your creative partners, innovators, and digital storytellers based in the vibrant heart of Perth, Western Australia. Whether you’re dreaming up a brand-new concept or looking to breathe fresh life into your existing website or app, our team is here to turn your vision into something extraordinary that truly stands out.
                                    </p>
                                    <p className="text-base md:text-lg font-light my-4 md:my-5">
                                        Already have a website or app but feel like it’s lost its edge? We’ve got you covered. Our specialty is taking what you already have and giving it a sleek, modern makeover that keeps you ahead of the game. Whether it’s optimizing for mobile, enhancing user engagement, or creating a seamless experience across all devices, we make sure every digital touchpoint is not only innovative but also impactful and future-proof.
                                    </p>
                                    <p className="text-base md:text-lg font-light my-4 md:my-5">
                                        Got a brilliant idea? We’ve got the expertise to bring it to life. With a portfolio packed with successful websites, mobile apps, and social media projects, we know how to take your concept and turn it into something your audience will love. Our creative team works hand in hand with you, ensuring that every solution we design resonates with your target market, helping you grow and get noticed.
                                    </p>
                                    <p className="text-base md:text-lg font-light my-4 md:my-5">
                                        Tokyo Digital makes sure every click, swipe, and tap leaves a lasting impact. Your digital future starts here, with us. Let’s make magic happen. Reach out today and see how we can take your project to the next level.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Footer */}
                    <Footer />
                </div>
            </div> 
        </>
    )

}