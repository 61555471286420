/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import { FaEnvelope, FaFacebookF, FaHeart, FaInstagram, FaLinkedinIn, FaMapPin, FaPhone, FaTiktok } from 'react-icons/fa';

export const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-black text-white py-6">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <img
                    src={require('../../assets/images/Logo.png')}
                    alt="Logo"
                    className="w-[200px] h-auto mb-4"
                />
                {/* Top area: Blocks */}
                <div className="grid grid-cols-1 ml-5 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-20 mb-8 md:mb-12">

                    {/* Logo Block */}
                    <div className="text-sm flex flex-col items-start md:items-start">
                        <h6 className="font-medium uppercase mb-2">Contact Us</h6>
                        <ul className="space-y-1">
                            <li>
                                <a
                                    className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out"
                                    href="tel:0459353043"
                                >
                                    <FaPhone className="mr-2" /> 0459353043
                                </a>
                            </li>
                            <li>
                                <a
                                    className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out"
                                    href="mailto:hello@tokyodigital.com.au"
                                >
                                    <FaEnvelope className="mr-2" /> hello@tokyodigital.com.au
                                </a>
                            </li>
                            <li>
                                <a className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out">
                                    <FaMapPin className="mr-2" /> Perth, Western Australia, 6000
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Site Links */}
                    <div className="text-sm flex flex-col items-start md:items-start">
                        <h6 className="font-medium uppercase mb-2">Site</h6>
                        <ul className="space-y-1">
                            <li>
                                <Link className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" to="/">Home</Link>
                            </li>
                            <li>
                                <Link className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" to="/#work">Work with us</Link>
                            </li>
                            <li>
                                <Link className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" to="/#services">Services</Link>
                            </li>
                            <li>
                                <Link className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" to="/#about">About</Link>
                            </li>
                            <li>
                                <Link className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out" to="mailto:hello@tokyodigital.com.au">Contact</Link>
                            </li>
                        </ul>
                    </div>

                    {/* Social Links */}
                    <div className="text-sm flex flex-col items-start md:items-start">
                        <h6 className="font-medium uppercase mb-2">Socials</h6>
                        <ul className="space-y-1">
                            <li>
                                <a
                                    className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out"
                                    href="https://www.facebook.com/tokyodigitalAU"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaFacebookF className="mr-2" /> Facebook
                                </a>
                            </li>
                            <li>
                                <a
                                    className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out"
                                    href="https://www.linkedin.com/company/digassitsAU"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaLinkedinIn className="mr-2" /> LinkedIn
                                </a>
                            </li>
                            <li>
                                <a
                                    className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out"
                                    href="https://www.instagram.com/tokyodigitalAU"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaInstagram className="mr-2" /> Instagram
                                </a>
                            </li>
                            <li>
                                <a
                                    className="flex items-center text-gray-100 hover:text-gray-300 transition duration-150 ease-in-out"
                                    href="https://www.tiktok.com/@tokyodigitalAU"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaTiktok className="mr-2" /> TikTok
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Footer Bottom Section */}
                <div className="flex flex-col md:flex-row justify-between items-center text-sm text-gray-400 mt-6">
                    <p className="mb-2 md:mb-0">&copy; {currentYear} Tokyo Digital. All rights reserved.</p>
                    <p className="flex items-center">
                        Made with <FaHeart className="text-red-500 mx-1 text-xl" /> in Perth, Western Australia
                    </p>
                </div>
            </div>
        </footer>
    );
};
